import { useSession } from "@blitzjs/auth"
import { invoke, useMutation } from "@blitzjs/rpc"
import { UniqueIdentifier } from "@dnd-kit/core"
import { Objective } from "@prisma/client"
import constate from "constate"
import { useRouter } from "next/router"
import { useCallback, useEffect, useState } from "react"
import useDebounce from "src/core/hooks/useDebounce"
import useDidMountEffect from "src/core/hooks/useDidMountEffect"
import { capitalize, validateUrl } from "utils"
import {
  buildPath,
  deleteNodeById,
  findAndUpdateNodeChildren,
  findDifferences,
  findRootAndFlattenTree,
  flattenTree,
  mapTree,
  TreeNode,
  updateNodeById,
} from "../../utils/tree"
import { FlattenedItem } from "../core/components/Tree/types"
import getSuggestedObjectives from "../objective-users/queries/getSuggestedObjectives"
import { getYoutubeChannelTypeAndId } from "../providers/youtube/getChannelMetadata"
import useObjectiveTree from "./hooks/useObjectiveTree"
import processObjectiveToObjectives from "./mutations/processObjectiveToObjectives"
import { FILLER_CLAUSES, ObjectiveStatus } from "./objectives.constants"
import { ICreateObjectivePayload, IObjectiveResult } from "./objectives.interfaces"
import ObjectivesService from "./objectives.service"

interface IObjectivesProviderProps {
  userId?: number
}

const useObjectives = ({ userId }: IObjectivesProviderProps) => {
  const { userId: currentUserId } = useSession({ suspense: false })
  const [isCurrentUser, setIsCurrentUser] = useState<boolean>(false)

  const [isViewOnly, setIsViewOnly] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [objectives, setObjectives] = useState<IObjectiveResult[]>([])
  const [isAdding, setIsAdding] = useState<boolean>(false)

  const [searchInput, setSearchInput] = useState<string>("")
  const debouncedInput = useDebounce(searchInput, 500)

  const [objectiveStatus, setObjectiveStatus] = useState(ObjectiveStatus.ACTIVE)
  const [objectiveStatusCounts, setObjectiveStatusCounts] = useState<{ [key: string]: number }>({})

  const onSetActive = useCallback(
    (objectiveId: number, isActive: boolean) => {
      if (searchInput.length) {
        setObjectives(
          objectives.map((objective) => {
            if (objective.id === objectiveId) {
              objective.isActive = isActive
            }
            return objective
          })
        )
      } else {
        // setObjectives(objectives.filter((objective) => objective.id !== objectiveId))
        const newObjectives = deleteNodeById(objectives as any, objectiveId) as IObjectiveResult[]
        setObjectives(newObjectives)
        update(newObjectives as FlattenedItem[])

        setObjectiveStatusCounts({
          ...objectiveStatusCounts,
          [ObjectiveStatus.ACTIVE]:
            objectiveStatusCounts[ObjectiveStatus.ACTIVE]! + (isActive ? 1 : -1),
          [ObjectiveStatus.INACTIVE]:
            objectiveStatusCounts[ObjectiveStatus.INACTIVE]! + (isActive ? -1 : 1),
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [objectives, searchInput.length]
  )

  const onSetPrivate = useCallback(
    (objectiveId: number, isPrivate: boolean) => {
      if (searchInput.length) {
        setObjectives(
          objectives.map((objective) => {
            if (objective.id === objectiveId) {
              objective.isPrivate = isPrivate
            }
            return objective
          })
        )
      } else {
        // setObjectives(objectives.filter((objective) => objective.id !== objectiveId))
        const newObjectives = deleteNodeById(objectives as any, objectiveId) as IObjectiveResult[]
        setObjectives(newObjectives)
        update(newObjectives as FlattenedItem[])
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [objectives, searchInput.length]
  )

  const getObjectives = useCallback(async () => {
    setIsLoading(true)

    if (userId === undefined && currentUserId !== null) {
      if (objectiveStatus !== ObjectiveStatus.SUGGESTED) {
        const { results, counts } = await ObjectivesService.getOwnObjectives(
          objectiveStatus === ObjectiveStatus.INACTIVE
        )

        setObjectives(results)
        setObjectiveStatusCounts(counts)
      } else {
        const suggestedObjectives = await invoke(getSuggestedObjectives, {})
        setObjectives(suggestedObjectives as IObjectiveResult[])
        setObjectiveStatusCounts({
          ...objectiveStatusCounts,
          [ObjectiveStatus.SUGGESTED]: suggestedObjectives.length,
        })
      }
    } else if (userId !== undefined) {
      setObjectives(await ObjectivesService.getUserObjectives(userId))
    } else {
      setObjectives([])
    }

    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId, userId, objectiveStatus])

  useEffect(() => {
    getObjectives().catch((_err) => {})
  }, [getObjectives])

  useEffect(() => {
    if (currentUserId) {
      setIsViewOnly(false)
      setIsCurrentUser(currentUserId === userId || !userId)
    } else {
      setIsCurrentUser(false)
      setIsViewOnly(true)
    }
  }, [currentUserId, userId])

  // Objective Adding
  const addObjective = async (payload: ICreateObjectivePayload) => {
    setIsAdding(true)
    const objective = await ObjectivesService.addObjective(payload)
    if (objective) {
      setObjectives([objective, ...objectives])
    }
    setSearchInput("")
    setIsAdding(false)
  }

  const onAdopt = async () => {
    setSearchInput("")
    if (objectiveStatus === ObjectiveStatus.SUGGESTED) {
      setObjectiveStatus(ObjectiveStatus.ACTIVE)
    }
  }

  const onAbandon = async (objectiveId: number) => {
    if (searchInput.length) {
      setObjectives(
        objectives.map((objective) => {
          if (objective.id === objectiveId) {
            objective.adoptedAt = undefined
            if (objective.userCount) {
              objective.userCount--
            }
          }
          return objective
        })
      )
    } else if (!isViewOnly) {
      if (isCurrentUser) {
        const newObjectives = deleteNodeById(objectives as any, objectiveId) as IObjectiveResult[]
        setObjectives(newObjectives)
        update(newObjectives as FlattenedItem[])

        // Describe count for active tab
        setObjectiveStatusCounts({
          ...objectiveStatusCounts,
          [ObjectiveStatus.ACTIVE]: objectiveStatusCounts[ObjectiveStatus.ACTIVE]! - 1,
        })
      } else {
        objectives.map((objective) => {
          if (objective.id === objectiveId) {
            objective.adoptedAt = undefined
            if (objective.userCount) {
              objective.userCount--
            }
          }
          return objective
        })
      }
    } else {
      setObjectives(
        objectives.map((objective) => {
          if (objective.id === objectiveId) {
            objective.adoptedAt = undefined
          }
          return objective
        })
      )
    }
  }

  // Search State
  useDidMountEffect(() => {
    if (searchInput) {
      setIsLoading(true)
    } else {
      getObjectives().catch((_err) => {})
    }
  }, [searchInput])

  const router = useRouter()

  useEffect(() => {
    if (debouncedInput) {
      if (validateUrl(debouncedInput)) {
        const result = getYoutubeChannelTypeAndId(debouncedInput)
        if (result) {
          router
            .push(
              {
                pathname: "/users",
              },
              {
                query: { q: debouncedInput },
              },
              { shallow: true }
            )
            .catch(console.error)
          return
        }

        router
          .push(
            {
              pathname: "/resources",
            },
            {
              query: { q: debouncedInput },
            },
            { shallow: true }
          )
          .catch(console.error)
      } else {
        // Remove filler clauses
        const refinedInput = FILLER_CLAUSES.reduce((previous, current) => {
          return capitalize(previous).replace(capitalize(current), "")
        }, debouncedInput).trim()

        if (refinedInput !== debouncedInput) {
          setSearchInput(refinedInput)
        } else {
          ObjectivesService.searchObjectives(refinedInput.toLowerCase())
            .then(async (results) => {
              setObjectives(results)
              setIsLoading(false)
              if (userId === undefined) {
                await router.push(
                  {
                    pathname: "/",
                    query: { q: refinedInput },
                  },
                  undefined,
                  { shallow: true }
                )
              }
            })
            .catch((_err) => {})
        }
      }
    } else {
      if (userId === undefined) {
        router
          .push(
            {
              pathname: "/",
            },
            undefined,
            { shallow: true }
          )
          .catch(console.error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput])

  useEffect(() => {
    const url = new URL(window.location as any)
    if (url.searchParams.get("q")) {
      setSearchInput(url.searchParams.get("q")!)
    }
  }, [])

  const { currentItems, initialItems, setInitialItems, onOrderChange, setCurrentItems, update } =
    useObjectiveTree(objectives)
  const [invokeProcessObjectiveToObjective] = useMutation(processObjectiveToObjectives)

  const saveOutline = async () => {
    try {
      let actions = findDifferences(initialItems as any, currentItems as any)

      actions = actions.filter(
        (action, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.parentId === action.parentId &&
              t.childId === action.childId &&
              t.type === action.type
          )
      )
      if (actions.length === 0) {
        return
      }

      const addActions = actions.filter((action, index, self) => {
        return (
          action.type === "add" &&
          self.findIndex((a) => a.childId === action.childId && a.type === action.type) === index
        )
      })

      const deleteActions = actions.filter(
        (action) =>
          action.type === "remove" &&
          !addActions.some(
            (addAction) =>
              addAction.childId === action.childId && addAction.parentId === action.parentId
          )
      )

      await invokeProcessObjectiveToObjective([
        ...addActions
          .filter(({ childId, parentId }) => {
            return (childId as number) > 0 && (parentId as number) > 0
          })
          .map(({ childId, parentId }) => ({
            parentObjectiveId: parentId as number,
            objectiveId: childId as number,
            type: "add",
          })),
        ...deleteActions.map(({ childId, parentId }) => ({
          parentObjectiveId: parentId as number,
          objectiveId: childId as number,
          type: "remove",
        })),
      ])

      // await getObjectives()
    } catch (error) {
      console.error("Error saving outline", error)
    }
    return
  }

  useEffect(() => {
    saveOutline()
      .then(() => {
        setInitialItems(currentItems)
      })
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialItems, currentItems])

  // Maintain loading state for each item using a map
  const [isLoadingMap, setIsLoadingMap] = useState<Record<UniqueIdentifier, boolean>>({})

  const getSubObjectives = (item: FlattenedItem) => {
    setIsLoadingMap((prev) => ({ ...prev, [item.id]: true }))

    const familyMembers = findRootAndFlattenTree(
      currentItems.filter((child) => !!(child as any).adoptedAt) as TreeNode[],
      item.id
    ).filter((child) => !!(child as any).adoptedAt)

    const memberNames = familyMembers.map((node) => node.name)

    const ancestors = buildPath(currentItems as TreeNode[], item.id)
    if (!ancestors) {
      setIsLoadingMap((prev) => ({ ...prev, [item.id]: false }))
      return
    }
    const simplifiedAncestors = mapTree(ancestors, (node) => {
      // if (node.children && node.children.length === 0) {
      //   delete node.children  // Remove children if empty
      // }
      return {
        // id: node.id,
        name: node.name,
        // children: node.children,
      }
    })

    const flattenedAncestors = flattenTree(simplifiedAncestors)
    const objectives = flattenedAncestors.map((node) => node.name)

    const indexes = objectives.map((objective) => memberNames.indexOf(objective))

    ObjectivesService.getSubObjectives({
      indexes,
      family: memberNames,
    })
      .then((children) => {
        let updatedItems: any[] = findAndUpdateNodeChildren(
          currentItems as TreeNode[],
          item.id,
          children.filter((child) => child.name !== item.name),
          ["adoptedAt"]
        ) as FlattenedItem[]
        // updateNodes(updatedItems, { collapsed: true })
        updatedItems = updateNodeById([...updatedItems] as any[], item.id, { collapsed: false })
        setCurrentItems((prev) => [...updatedItems])
        // update(updatedItems)
      })
      .catch(console.error)
      .finally(() => {
        setIsLoadingMap((prev) => ({ ...prev, [item.id]: false }))
      })
  }

  const goToObjective = (id: number) => {
    const ancestors = buildPath(currentItems as TreeNode[], id)
    if (!ancestors) {
      setIsLoadingMap((prev) => ({ ...prev, [id]: false }))
      return
    }
    const simplifiedAncestors = mapTree(ancestors, (node) => {
      return {
        id: node.id,
      }
    })

    const flattenedAncestors = flattenTree(simplifiedAncestors)

    router.push("/objectives/" + flattenedAncestors.map((o) => o.id).join("/")).catch(console.error)
  }

  // Objective Notes
  const [noteObjective, setNoteObjective] = useState<Objective>()

  return {
    isViewOnly,
    objectives,
    getObjectives,
    setObjectives,
    isLoading,
    setIsLoading,

    addObjective,
    isAdding,

    searchInput,
    debouncedInput,
    setSearchInput,

    onAdopt,
    onAbandon,

    isCurrentUser,
    userId,

    objectiveStatus,
    setObjectiveStatus,
    onSetActive,
    onSetPrivate,
    objectiveStatusCounts,
    currentItems,
    onOrderChange,
    setCurrentItems,
    getSubObjectives,
    isLoadingMap,
    goToObjective,

    noteObjective,
    setNoteObjective,
    update,
  }
}

export const [ObjectivesProvider, useObjectivesContext] = constate(useObjectives)
